import React from "react"
import Services from "../../api/service"
import { Link } from "react-router-dom"

const ServiceSidebar = props => {
  const SubmitHandler = e => {
    e.preventDefault()
  }

  const ClickHandler = () => {
    window.scrollTo(10, 0)
  }

  return (
    <div className="col-lg-4 col-md-8">
      <div className="blog-sidebar">
        <div className="widget search-widget">
          <h3>Search Here</h3>
          <form onSubmit={SubmitHandler}>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Search Post.."
              />
              <button type="submit">
                <i className="ti-search"></i>
              </button>
            </div>
          </form>
        </div>
        <div className="widget category-widget">
          <h3>Related Services</h3>
          <ul>
            {Services.slice(0, 6).map((service, Sitem) => (
              <li key={Sitem}>
                <Link
                  onClick={ClickHandler}
                  to={`/service-single/${service.Id}`}
                >
                  {service.sTitle}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServiceSidebar
