import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import { useParams } from "react-router-dom"
import Services from "../../api/service"
import ServiceSidebar from "./sidebar"
import Contact from "./contact"
import Footer2 from "../../components/footer2/Footer2"

const ServiceSinglePage = props => {
  const { id } = useParams()

  const serviceDetails = Services.find(item => item.Id === id)

  const ClickHandler = () => {
    window.scrollTo(10, 0)
  }

  return (
    <Fragment>
      <Navbar3 hclass={"wpo-header-style-5"} />
      <PageTitle pageTitle={serviceDetails.sTitle} pagesub={"Service"} />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.sImgS} alt="" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.sTitle}</h3>
                  </div>
                  {serviceDetails.fulldescription}
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={serviceDetails.ssImg1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={serviceDetails.ssImg2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Capabilities</h3>
                  </div>
                  <p>
                    Prediction Technologies boasts a robust capability derived
                    from over two decades of experience in delivering
                    sophisticated software solutions and expert consultancy
                    services. Our prowess lies in crafting innovative tools like
                    R-DAT and BRASS, empowering industries with advanced data
                    analytics and reliable decision-making capabilities. We
                    excel in offering comprehensive support, expert training,
                    and personalized guidance, ensuring seamless integration and
                    maximum value for our clientele.
                  </p>
                  <ul>
                    <li>
                      Advanced Software Solutions: Development of cutting-edge
                      tools like R-DAT and BRASS for enhanced data analytics.
                    </li>
                    <li>
                      Expert Consultancy: Two decades of experience in providing
                      reliable guidance for decision-making processes.
                    </li>
                    <li>
                      Comprehensive Support: Offering expert training, live
                      demonstrations, and personalized assistance for seamless
                      integration.
                    </li>
                    <li>
                      Industry Experience: Over Two Decades of expertise in
                      addressing diverse industry demands and user requisites.
                    </li>
                    <li>
                      Continuous Innovation: Commitment to evolving solutions in
                      line with technological advancements and user feedback.
                    </li>
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>
                    At Prediction Technologies, our approach hinges on a
                    harmonious fusion of innovation and expertise, meticulously
                    tailored to address industry demands and user requisites. We
                    leverage this approach to craft advanced software solutions
                    like R-DAT and BRASS. Central to our ethos is
                    customer-centricity, ensuring we provide comprehensive
                    support, expert training, and live demonstrations. Our
                    commitment to reliability and continuous improvement drives
                    our approach, shaping solutions that empower industries and
                    refine decision-making processes.
                  </p>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    <li>
                      Iterative Refinement: Continuously adapt solutions based
                      on technological advancements and user feedback
                    </li>
                    <li>
                      Customer-Centric Support: Offer comprehensive assistance,
                      expert training, and live demonstrations for seamless
                      integration.
                    </li>
                    <li>
                      Industry Analysis: Meticulously analyze industry demands
                      and user needs to develop cutting-edge software.
                    </li>
                    <li>
                      Commitment to Improvement: Prioritize reliability and
                      precision, driving our process toward exceptional
                      solutions for empowered decision-making.
                    </li>
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Related Service</h3>
                  </div>
                  <div className="wpo-service-area">
                    <div className="row align-items-center">
                      {Services.slice(0, 3).map((service, srv) => (
                        <div className="col-lg-4 col-md-6 col-12" key={srv}>
                          <div className="wpo-service-item">
                            <i className={`fi ${service.icon}`}></i>
                            <h2>{service.sTitle}</h2>
                            <p>{service.des2}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-contact-area">
                    <div className="wpo-contact-title">
                      <h2>Have project in mind? Let's discuss</h2>
                      <p>
                        Get in touch with us to see how we can help you with
                        your project
                      </p>
                    </div>
                    <div className="wpo-contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default ServiceSinglePage
