import React, { Fragment } from "react"
import About from "../../components/about/about"
import CtaSection from "../../components/ctaSection/ctaSection"
import Footer from "../../components/footer/Footer"
import Hero from "../../components/hero/hero"
import Navbar from "../../components/Navbar/Navbar"
import Scrollbar from "../../components/scrollbar/scrollbar"
import ServiceSection from "../../components/ServiceSection/ServiceSection"
import PartnerSection from "../../components/PartnerSection/PartnerSection"
import ContactInfo from "../../components/ContactInfo/ContactInfo"
import abimg from "../../images/about.jpg"

const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-1"} topbarNone={"topbar-none"} />
      <Hero />
      <About abimg={abimg} />
      <ServiceSection />
      <CtaSection />
      <PartnerSection />
      <ContactInfo />
      <Footer />
      <Scrollbar />
    </Fragment>
  )
}
export default HomePage
