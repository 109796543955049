import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import Footer2 from "../../components/footer2/Footer2"

const PrivacyPage = props => {
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"Privacy Policy"} pagesub={"Privacy Policy"} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-text">
                  <h2>Privacy Policy</h2>
                  <p>
                    This website is owned and operated by Prediction
                    Technologies Inc.
                  </p>
                  <p>
                    Information we collect from users of the site will be stored
                    by us on computers in the USA and may be used in the
                    following ways:
                  </p>
                  <h4>Contact Details:</h4>
                  <p>
                    We will use your contact details (your name, job title,
                    telephone and fax number and your e-mail and postal
                    addresses) to provide any services requested by you and also
                    to tell you about important changes to our software and its
                    functionality. These details will be used by us to provide
                    you with information about other services and products and
                    will not be disclosed to third party businesses and
                    advertisers. If you do not wish to be contacted for this
                    purpose, please express your preferences on the registration
                    page.
                  </p>

                  <h4>To Alter Your Details:</h4>
                  <p>
                    If your contact details change, you can alter the details we
                    hold by e-mailing: info@prediction-technologies.com.
                  </p>
                  <h4>Access to Data:</h4>
                  <p>
                    You have certain rights under the Data Protection Acts 1984
                    and 1988. You have the right to be told what personal
                    information we hold about you on our databases. You should
                    write to us stating your full name and your address at:
                  </p>
                  <section className="wpo-contact-pg-section section-padding">
                    <div className="container">
                      <div className="row">
                        <div className="col col-lg-10 offset-lg-1">
                          <div className="office-info">
                            <div className="row">
                              <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="office-info-item">
                                  <div className="office-info-icon">
                                    <div className="icon">
                                      <i className="fi flaticon-place"></i>
                                    </div>
                                  </div>
                                  <div className="office-info-text">
                                    <h2>Database Enquiries</h2>
                                    <p>
                                      9520 Berger Road, Suite 212 Columbia, MD
                                      21046 USA
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <h4>Changes to Privacy Policy:</h4>
                  <p>Any changes to the privacy policy will be posted here.</p>
                  <p>Last updated: Nov 20, 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default PrivacyPage
