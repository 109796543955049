import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import Footer2 from "../../components/footer2/Footer2"
import shape1 from "../../images/team/shape6.png"
import shape2 from "../../images/progress-shape-1.png"
import shape3 from "../../images/progress-shape-2.png"
import trilithimage from "../../images/slider/5.png"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

const TrilithPage = () => {
  const SubmitHandler = e => {
    e.preventDefault()
  }

  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"TRILITH"} pagesub={"Products"} />
      <div className="team-pg-area">
        <div className="team-inner section-padding">
          <div className="container">
            <div className="team-info-wrap">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="team-info-img">
                    <img src={trilithimage} alt="" />
                    <div className="shape">
                      <img src={shape1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="education-area ex-wiget">
                    <h2>Trilith</h2>
                    <p>
                      Trilith is a tool that combines a user-friendly graphical
                      interface with a powerful computational engine to model
                      many issues in risk and system safety assessment. It
                      employs a model-based systems approach that can be used as
                      the foundation for addressing many of the issues commonly
                      encountered in the field. The tool combines Boolean logic
                      advanced risk assessment methods (Event Sequence Diagrams
                      and Fault Trees) used by other industries with Bayesian
                      Belief Networks and new concepts developed by UMD.
                    </p>
                    <p>
                      Trilith provides a vehicle for identification and analysis
                      of cause-effect relations between the elements of any
                      large system and various types of accidents and incidents.
                      The modeling domain includes events associated with human,
                      organization, hardware, software, and physical and
                      regulatory environments of the system. The hybrid
                      methodology imposed by Trilith extends the conventional
                      risk analysis techniques by introducing new techniques
                      necessary to capture the diversity and complexity of the
                      potential hazards in modern systems, and provides
                      additional flexibility with respect to data availability
                      and practical constraints.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-shape-1">
            <img src={shape2} alt="" />
          </div>
          <div className="pr-shape-2">
            <img src={shape3} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="exprience-area">
            <div className="row">
              <div className="col-lg-12">
                <div className="exprience-wrap">
                  <h2>Features</h2>
                  <p>
                    Trilith can be used as the foundation for addressing many of
                    the issues that are commonly encountered in system safety
                    assessment, hazard identification, and risk analysis. The
                    Trilith framework offers multiple ways to assess system
                    safety and can be used for both generic and specific
                    scenarios. Users have the ability to trace causal paths from
                    root causes to undesired events. Trilith assesses the
                    probability of individual risk scenarios and the total risk
                    for various systems and risk categories. It also identifies
                    risk contributors and root causes of failure and quantifies
                    the importance of various factors on overall system risk.
                  </p>
                  <p>
                    The flexible Trilith framework gives users the ability to
                    develop three types of diagrams and allows the user to
                    analyze them independently or as an overall system. The
                    software introduces logical connections between Bayesian
                    Belief Networks, Fault Trees and Event Sequence Diagrams in
                    order to represent causal dependencies between the events in
                    those models.
                  </p>
                  <h2>Installations</h2>
                  <p>
                    Please note that Trilith requires .NET Framework 2.0. You
                    may not be able to run the installer if you do not have .NET
                    Framework installed.
                  </p>
                  <p>
                    You can enable the .NET Framework 3.5 through the Windows
                    Control Panel. Simply Press the Windows key on your
                    keyboard, type "Windows Features", and press Enter. The Turn
                    Windows features on or off dialog box appears. Select the
                    .NET Framework 3.5 (includes .NET 2.0 and 3.0) check box,
                    select OK, and reboot your computer if prompted.
                  </p>
                  <p>
                    You don't need to select the child items for Windows
                    Communication Foundation (WCF) HTTP Activation and Windows
                    Communication Foundation (WCF) Non-HTTP Activation unless
                    you're a developer or server administrator who requires this
                    functionality.
                  </p>
                </div>
                <div className="download-area">
                  <h2>Downloads</h2>
                  <Table className="download-table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: "18px" }}>
                          Products
                        </TableCell>
                        <TableCell style={{ fontSize: "18px" }}>
                          Version
                        </TableCell>
                        <TableCell style={{ fontSize: "18px" }}>
                          Release Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="download-table-body">
                      <TableRow>
                        <TableCell style={{ fontSize: "16px" }}>
                          Trilith
                        </TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          <a href="downloads/trilith-2.0.1.msi">2.0.1</a>
                        </TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          06/22/2024
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default TrilithPage
