import React from "react"
import simg from "../../images/mission-shape.png"

const MissionVission = props => {
  return (
    <section className="wpo-mission-vission-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-mission-item">
              <div className="wpo-mission-text">
                <h2>Our Mission</h2>
                <p>
                  Prediction Technologies is committed to  providing cutting-edge software tools,
                  facilitating intricate data analysis and enabling engineers and decision makers to
                  derive precise reliability estimates, even in scenarios with
                  limited data availability. Our mission extends beyond software
                  delivery; we are dedicated to ongoing innovation, ensuring our
                  solutions evolve alongside technological advancements.
                  Moreover, our comprehensive support services, including expert
                  training and personalized guidance, ensure seamless
                  integration and maximized value for our clients, empowering
                  them to make informed decisions confidently.
                </p>
                <h5>
                  Our mission is to empower industries with cutting-edge
                  software solutions and expert guidance, fostering reliability,
                  innovation, and informed decision-making through continuous
                  improvement and comprehensive support services.
                </h5>
              </div>
              <div className="shape-1"></div>
              <div className="shape-2">
                <img src={simg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-vission-item">
              <div className="wpo-vission-text">
                <h2>Our Vission</h2>
                <h5>
                  Our vision at Prediction Technologies is to become the
                  industry leader in advanced data analytics and reliability
                  consultancy, setting new standards for innovation, excellence,
                  and customer satisfaction worldwide.
                </h5>
                <p>
                  We envision Prediction Technologies as the foremost provider
                  of unparalleled data analytics and reliability consultancy
                  services, redefining industry norms and exceeding expectations
                  in innovation and customer service. Our ambition is to be
                  globally recognized for our commitment to reliability,
                  precision, and continuous innovation. Our goal is to be the trusted partner for organizations
                  seeking informed, data-driven decision-making capabilities,
                  fostering a culture of empowerment and technological
                  advancement across diverse sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MissionVission
