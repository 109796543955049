import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import Footer2 from "../../components/footer2/Footer2"
import shape1 from "../../images/team/shape6.png"
import shape2 from "../../images/progress-shape-1.png"
import shape3 from "../../images/progress-shape-2.png"
import rdatimage from "../../images/slider/1.png"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

const RDATPage = () => {
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"R-DAT"} pagesub={"Products"} />
      <div className="team-pg-area">
        <div className="team-inner section-padding">
          <div className="container">
            <div className="team-info-wrap">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="team-info-img">
                    <img src={rdatimage} alt="" />
                    <div className="shape">
                      <img src={shape1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="education-area ex-wiget">
                    <h2>R-DAT</h2>
                    <p>
                      R-DAT is a Bayesian data analysis package designed
                      specifically for risk analysts and reliability engineers
                      who frequently need to develop and update system-specific
                      reliability parameter estimates.
                    </p>
                    <p>Using R-DAT To:</p>
                    <ul>
                      <li>Store and organize reliability data</li>{" "}
                      <li>
                        Develop generic and system-specific failure rate
                        distributions using powerful Bayesian algorithms
                      </li>
                      <li>
                        View results using easy-to-understand graphical and
                        tabular displays.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-shape-1">
            <img src={shape2} alt="" />
          </div>
          <div className="pr-shape-2">
            <img src={shape3} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="exprience-area">
            <div className="row">
              <div className="col-lg-12">
                <div className="exprience-wrap">
                  <h2>Features</h2>
                  <p>
                    R-DAT provides you with a powerful, yet simple and flexible
                    environment for storing and organizing many types of
                    reliability data and related information. A hierarchical
                    structure enables the user to develop functional or
                    structural or any other type of system decomposition, at any
                    level of detail. The elements of this hierarchy act as
                    folders containing the reliability data and the results of
                    Bayesian analyses performed on the data sets.
                  </p>
                  <p>
                    In a few simple steps, R-DAT enables you to update your
                    prior distributions (generic, or from previous updates) with
                    new system-specific data using likelihood functions that
                    match the type of data (for example, Binomial likelihood for
                    failure on demand, Poisson likelihood for failure during
                    operation). Prior and Posterior distribution results are
                    displayed graphically in p.d.f. or c.d.f. formats together
                    with numerical values of various percentiles and other
                    properties of the distributions.
                  </p>
                  <p>
                    With R-DAT you can specify the prior distribution in many
                    different ways depending on the type and level of
                    information available to you. These include a wide variety
                    of parametric distributions (e.g., lognormal, beta and
                    log-uniform) using any of a number of input options such as
                    lower and upper bounds, mean and variance, or the
                    distribution parameters.
                  </p>
                  <p>
                    Results can easily be copied to other software environments,
                    such as Microsoft Word and Microsoft Excel. Furthermore,
                    R-DAT includes a reporting mechanism that allows event
                    distribution reports to be generated automatically.
                  </p>
                </div>
                <div className="download-area">
                  <h2>Downloads</h2>
                  <Table className="download-table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: "18px" }}>
                          Products
                        </TableCell>
                        <TableCell style={{ fontSize: "18px" }}>
                          Version
                        </TableCell>
                        <TableCell style={{ fontSize: "18px" }}>
                          Release Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="download-table-body">
                      <TableRow>
                        <TableCell style={{ fontSize: "16px" }}>RDAT</TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          <a href="downloads/rdat-2.0.0.exe">2.0.0</a>
                        </TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          12/1/2023
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: "16px" }}>RDAT</TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          <a href="downloads/rdat-1.5.8.msi">1.5.8</a>
                        </TableCell>
                        <TableCell style={{ fontSize: "16px" }}>
                          6/1/2013
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default RDATPage
