import React, { Fragment, useState } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/List"
import Collapse from "@material-ui/core/Collapse"
import { NavLink } from "react-router-dom"
import "./style.css"

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/home",
  },

  {
    id: 2,
    title: "Our Company",
    link: "/about",
    submenu: [
      {
        id: 21,
        title: "About Us",
        link: "/about",
      },
      {
        id: 22,
        title: "What we do",
        link: "/service",
      },
    ],
  },
  {
    id: 3,
    title: "Products",
    link: "",
    submenu: [
      {
        id: 31,
        title: "RDAT",
        link: "/rdat",
      },
      {
        id: 32,
        title: "BRASS",
        link: "/brass",
      },
      {
        id: 33,
        title: "TRILITH",
        link: "/trilith",
      },
    ],
  },
  {
    id: 4,
    title: "Online Documents",
    link: "",
    submenu: [
      {
        id: 41,
        title: "R-DAT Online Help",
        link: "https://prediction-technologies.atlassian.net/wiki/spaces/ROH/overview",
      },
      {
        id: 42,
        title: "Brass Online Help",
        link: "https://prediction-technologies.atlassian.net/wiki/spaces/BOH/overview",
      },
      {
        id: 43,
        title: "Trilith Online Help",
        link: "https://prediction-technologies.atlassian.net/wiki/spaces/TOH/overview",
      },
    ],
  },
  {
    id: 88,
    title: "Contact",
    link: "/contact",
  },
]

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0)
  const [menuActive, setMenuState] = useState(false)

  const ClickHandler = () => {
    window.scrollTo(10, 0)
  }

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <NavLink
                                  onClick={ClickHandler}
                                  className="active"
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </NavLink>
                              </ListItem>
                            )
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <NavLink className="active" to={item.link}>
                    {item.title}
                  </NavLink>
                )}
              </ListItem>
            )
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  )
}

export default MobileMenu
