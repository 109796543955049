import React from "react"
import { Link } from "react-router-dom"
import BlogSidebar from "../BlogSidebar/BlogSidebar.js"
import VideoModal from "../ModalVideo/VideoModal"

import blogs from "../../api/blogs"

const ClickHandler = () => {
  window.scrollTo(10, 0)
}

const BlogList = props => {
  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container"></div>
    </section>
  )
}

export default BlogList
