import React from "react"
import Slider from "react-slick"
import { Link } from "react-router-dom"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import hero1 from "../../images/slider/1.png"
import hero2 from "../../images/slider/4.png"
import hero3 from "../../images/slider/5.png"

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2500,
  fade: true,
}

const Hero = () => {
  return (
    <section className="hero hero-slider-wrapper hero-style-1">
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                  <div className="slide-title-sub">
                    <h5>Bayesian Data Analysis.</h5>
                  </div>
                  <div className="slide-title">
                    <h2>R-DAT</h2>
                  </div>
                  <div className="slide-subtitle">
                    <p>
                      Full-featured Bayesian data analysis package for risk and
                      reliability analysts. It is designed for users who need to
                      perform system-specific analyses, but who also have a need
                      to develop generic prior distributions based on other
                      available information such as industry data.
                    </p>
                  </div>
                  <div className="btns">
                    <Link to="/rdat" className="theme-btn">
                      Explore More
                    </Link>
                  </div>
                  <div className="slider-pic">
                    <img src={hero1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                  <div className="slide-title-sub">
                    <h5>Weibull Analysis Tool.</h5>
                  </div>
                  <div className="slide-title">
                    <h2>BRASS</h2>
                  </div>
                  <div className="slide-subtitle">
                    <p>
                      BRASS is the state-of-the art product reliability
                      assessment tool that helps engineers to develop
                      reliability estimates for products in design or already in
                      operation. This tool enables you to develop these
                      estimates where this previously seemed impossible because
                      reliability performance data is limited or completely
                      absent.
                    </p>
                  </div>
                  <div className="btns">
                    <Link to="/brass" className="theme-btn">
                      Explore More
                    </Link>
                  </div>
                  <div className="slider-pic">
                    <img src={hero2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                  <div className="slide-title-sub">
                    <h5>Integrated Risk Information System.</h5>
                  </div>
                  <div className="slide-title">
                    <h2>TRILITH</h2>
                  </div>
                  <div className="slide-subtitle">
                    <p>
                      Trilith is a tool that combines Boolean logic advanced
                      risk assessment methods with Bayesian Belief Networks. The
                      hybrid methodology imposed by Trilith extends the
                      conventional risk analysis techniques by introducing new
                      techniques necessary to capture the diversity and
                      complexity of the potential hazards in modern systems, and
                      provides additional flexibility with respect to data
                      availability and practical constraints.
                    </p>
                  </div>
                  <div className="btns">
                    <Link to="/trilith" className="theme-btn">
                      Explore More
                    </Link>
                  </div>
                  <div className="slider-pic">
                    <img src={hero3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default Hero
