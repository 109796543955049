import React from "react"

const HeaderTopbar2 = () => {
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-lg-12 col-sm-12 col-12">
            <div className="note">
              <p>
                <span>Notice:</span> Prediction Technologies Inc is proud to
                announce the release of online help documents for both RDAT and
                BRASS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTopbar2
