import simg from "../images/service/1.jpg"
import simg2 from "../images/service/2.jpg"
import simg3 from "../images/service/3.jpg"
import simg4 from "../images/service/4.jpg"

import sImgS1 from "../images/service-single/img-1.jpg"
import sImgS2 from "../images/service-single/img-2.jpg"
import sImgS3 from "../images/service-single/img-3.jpg"
import sImgS4 from "../images/service-single/img-4.jpg"

import sSingleimg1 from "../images/service-single/2.jpg"
import sSingleimg2 from "../images/service-single/3.jpg"

const Services = [
  {
    Id: "1",
    sImg: simg,
    sImgS: sImgS1,
    sTitle: "Software Solutions",
    description:
      "Delivers sophisticated software tools, enabling in-depth data analysis and reliable estimation, catering to specific system needs while leveraging industry data for enhanced insights and predictions.",
    des2: "Software solutions offer advanced analytics, fostering precision, and reliability in decision-making for enhanced performance",
    fulldescription:
      "At Prediction Technologies, our Software Solutions, exemplified by R-DAT and BRASS, represent a pinnacle of innovation in data analytics and reliability enhancement. R-DAT offers a comprehensive Bayesian data analysis package, facilitating system-specific analyses and the development of generic prior distributions using industry data. Similarly, BRASS serves as a state-of-the-art product reliability assessment tool, enabling engineers to estimate product reliability, even in scenarios with limited or absent performance data. Continuously evolving, these solutions are meticulously crafted to meet industry-specific needs, emphasizing precision, reliability, and informed decision-making. Beyond functionality, our focus on user-centric design and intuitive interfaces aims to empower clients, fostering a culture of reliability and data-driven choices.",
    icon: "flaticon-advisor",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg2,
    sImgS: sImgS2,
    sTitle: "Innovative Adaptation",
    description:
      " Commits to ongoing software enhancements and technological updates, ensuring clients access state-of-the-art features that align with evolving industry requirements and software advancements.",
    des2: "Evolving software aligns with user needs, ensuring up-to-date features, and functionalities, staying ahead technologically.",
    fulldescription:
      "Innovative Adaptation lies at the heart of Prediction Technologies' approach, ensuring our software evolves harmoniously with technological advancements and user expectations. Through iterative refinement, we adapt our solutions, including R-DAT and BRASS, to embrace the latest innovations and address user feedback. This approach enables agility, keeping our tools at the forefront by delivering cutting-edge functionalities. Our proactive stance in anticipating emerging user requirements and leveraging leading-edge technologies ensures seamless integration of our software into diverse industry landscapes. With a commitment to continuous enhancement, we guarantee access to advanced, industry-leading solutions that remain robust, flexible, and future-proof.",
    icon: "flaticon-team",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "3",
    sImg: simg3,
    sImgS: sImgS3,
    sTitle: "Customer Empowerment",
    description:
      "Providing a 30-day fully-featured trial for software with expert guidance, live demonstrations, and training for seamless integration and understanding.",
    des2: "Comprehensive support, expert training, and live demos enable seamless integration, maximizing value for informed decision-making.",
    fulldescription:
      "Customer Empowerment at Prediction Technologies embodies our commitment to providing comprehensive support and guidance, enabling seamless integration and maximum value for our clients. We offer expert training, live demonstrations, and personalized assistance, ensuring our clients harness the full potential of our solutions. By prioritizing customer-centricity, we facilitate informed decision-making and foster a collaborative environment where clients feel empowered to utilize our tools effectively. Our dedication to assisting clients through every stage of implementation reflects our mission to not only deliver software but also to empower individuals and organizations to achieve their objectives efficiently and confidently.",
    icon: "flaticon-taxes",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "4",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "Reliability & Risk Consulting",
    description:
      "Offers cutting-edge consultancy services, utilizing BRASS to help engineers derive product reliability estimates even in scenarios with limited or absent performance data, ensuring optimal product performance.",
    des2: "Empowers engineers for precise estimation, overcoming limited data scenarios, ensuring accurate reliability assessment and guidance.",
    fulldescription:
      "Reliability Consulting is the cornerstone of our commitment to precision and expertise at Prediction Technologies. Leveraging tools like BRASS, our expert consultancy services empower engineers to derive precise reliability estimates, overcoming challenges posed by limited performance data. Through years of experience and a meticulous approach, we guide clients in making informed decisions, ensuring the reliability and optimal performance of their products. Our consulting services extend beyond estimation; they embody a partnership aimed at enhancing reliability, elevating industry standards, and fostering a culture of trust and excellence in decision-making processes.",
    icon: "flaticon-tax",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
]

export default Services
