import React from "react"
import { Link } from "react-router-dom"
import abimg from "../../images/about/ab2.png"

const ClickHandler = props => {
  window.scrollTo(10, 0)
}

const About4 = props => {
  return (
    <section className="wpo-about-section-s4 section-padding pb-120">
      <div className="container">
        <div className="wpo-about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h4>Welcome To Prediction Technologies Inc</h4>
                <h2>We are trusted consulting company.</h2>
                <p>
                  As a trusted consulting company with over 23 years of
                  experience, we pride ourselves on being a leading software
                  provider dedicated to delivering unparalleled standards of
                  excellence. Our commitment lies in consistently offering
                  top-tier products and after-sales service that exceed
                  expectations. With a steadfast dedication to meeting user
                  requirements and keeping abreast of current software
                  technology, our products undergo continuous enhancements,
                  ensuring they remain at the forefront of innovation.
                  Additionally, our customers benefit from unwavering support
                  provided by a team of seasoned engineers and software
                  specialists, guaranteeing expert assistance and guidance every
                  step of the way. At our core, we prioritize trust,
                  reliability, and customer satisfaction, cementing our position
                  as a dependable and trusted partner in the realm of software
                  solutions and consultancy services.
                </p>
                <div className="text-sub-wrap">
                  <div className="text-sub-item">
                    <i className="fi flaticon-tax"></i>
                    <h5>
                      Perfect solution of Reliability, Data Analysis and Risk
                      Management
                    </h5>
                  </div>
                </div>
                <Link
                  onClick={ClickHandler}
                  className="theme-btn"
                  to="/service"
                >
                  Our Services
                </Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <div className="inner-img">
                    <img src={abimg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-ab"></div>
    </section>
  )
}

export default About4
