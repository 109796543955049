import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Footer2 from "../../components/footer2/Footer2"
import Scrollbar from "../../components/scrollbar/scrollbar"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
}))

const FAQPage = props => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"FAQ"} pagesub={"FAQ"} />
      <div className="wpo-benefits-section">
        <h2>Frequently Ask Questions</h2>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="wpo-benefits-item">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    The software is missing a license. What do I do?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The first time you run R-DAT after installation, you will
                    need to install a license code in order to activate the
                    software. If the software does not have a valid license, a
                    license dialog will be shown when the software is started.
                    Contact Prediction Technologies to request a key.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>
                    My permanent license is expired. What happened?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The license code you receive when you register your copy of
                    the software can be used to activate any version of R-DAT
                    released during the time that you subscribe to our software
                    support program. Therefore, if you renewed your support
                    contract and are trying to activate a new release, it may be
                    necessary to obtain a new license code.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography className={classes.heading}>
                    Where can I find the system ID?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The system ID is an alpha-numeric code generated by R-DAT
                    that is unique to your computer. In order to find the
                    number, start the R-DAT software. If the software hasn't
                    already been activated, a license information dialog
                    containing the system ID appears. Otherwise, use the
                    Help-License Info menu option in the Navigator to see the
                    code.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>
                    How do I perform two-stage Bayesian updating?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A two-stage Bayesian analysis can be performed by first
                    constructing a generic prior using the Non-Homogeneous
                    Analysis module, and to update this prior using the
                    Homogeneous Analysis module.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}

export default FAQPage
