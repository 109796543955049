import React, { useState } from "react"
import { Link } from "react-router-dom"
import HeaderTopbar2 from "../HeaderTopbar2/HeaderTopbar2"
import MobileMenu from "../MobileMenu/MobileMenu"
import Logo from "../../images/logo-2.png"

const Header3 = props => {
  const [menuActive, setMenuState] = useState(false)
  const SubmitHandler = e => {
    e.preventDefault()
  }

  const ClickHandler = () => {
    window.scrollTo(10, 0)
  }

  return (
    <header id="header" className={`wpo-header-style-7 ${props.topbarNone}`}>
      <HeaderTopbar2 />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        Our Company
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/about">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/service">
                            What We Do
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/service">Products</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/rdat">
                            R-DAT
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/brass">
                            BRASS
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/trilith">
                            TRILITH
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/blog">
                        Online Documents
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://prediction-technologies.atlassian.net/wiki/spaces/ROH/overview">
                            R-DAT Online Help
                          </a>
                        </li>
                        <li>
                          <a href="https://prediction-technologies.atlassian.net/wiki/spaces/BOH/overview">
                            Brass Online Help
                          </a>
                        </li>
                        <li>
                          <a href="https://prediction-technologies.atlassian.net/wiki/spaces/TOH/overview">
                            Trilith Online Help
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-2">
                <div className="header-right">
                  <div className="header-search-form-wrapper">
                    <div className="cart-search-contact">
                      <button
                        onClick={() => setMenuState(!menuActive)}
                        className="search-toggle-btn"
                      >
                        <i
                          className={`fi ti-search ${
                            menuActive ? "ti-close" : "fi "
                          }`}
                        ></i>
                      </button>
                      <div
                        className={`header-search-form ${
                          menuActive ? "header-search-content-toggle" : ""
                        }`}
                      >
                        <form onSubmit={SubmitHandler}>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                            />
                            <button type="submit">
                              <i className="fi flaticon-search-interface-symbol"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="close-form">
                    <Link
                      onClick={ClickHandler}
                      className="theme-btn"
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header3
