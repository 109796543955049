import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import Footer2 from "../../components/footer2/Footer2"

const LegalNoticePage = props => {
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"Legal Notice"} pagesub={"Legal Notice"} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-text">
                  <h2>Legal Notice</h2>
                  <p>
                    Your use of this web site hosted by Prediction Technologies
                    Inc and/or its various affiliates or subsidiaries is subject
                    to the following terms and conditions:
                  </p>
                  <h4>Copyright and Trade Marks:</h4>
                  <p>
                    All materials on this web site are the copyright of
                    Prediction Technologies Inc or are reproduced with
                    permission from other copyright owners. All rights are
                    reserved. The materials on this web site may be retrieved
                    and downloaded solely for personal use. No materials may
                    otherwise be copied, modified, published, broadcast or
                    otherwise distributed without the prior written permission
                    of Prediction Technologies Inc.
                  </p>
                  <p>
                    For information on obtaining permission to reproduce or
                    translate Prediction Technologies Inc's copyright material
                    email: support@prediction-technologies.com.
                  </p>
                  <h4>Disclaimer:</h4>
                  <p>
                    Prediction Technologies Inc makes no warranties or
                    representations of any kind concerning the accuracy or
                    suitability of the information contained on this web site
                    for any purpose. All such information is provided "as is"
                    and with specific disclaimer of any warranties of
                    merchantability, fitness for purpose, title and/or
                    non-infringement. Prediction Technologies Inc makes no
                    warranties or representations of any kind that the services
                    provided by this web site will be uninterrupted , error-free
                    or that the web site or the server that hosts the web site
                    are free from viruses or other forms of harmful computer
                    code. In no event shall Prediction Technologies Inc, its
                    employees or agents be liable for any direct, indirect or
                    consequential damages resulting from the use of this web
                    site. This exclusion and limitation only applies to the
                    extent permitted by law and is without prejudice to any
                    express provisions to the contrary in any written licence or
                    subscription agreement from Prediction Technologies Inc in
                    respect of the use of any online service provided via this
                    web site.
                  </p>
                  <h4>Links:</h4>
                  <p>
                    Links to other web sites are provided by Prediction
                    Technologies Inc in good faith and for information only.
                    Prediction Technologies Inc disclaims any responsibility for
                    the materials contained in any web site linked to this site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default LegalNoticePage
