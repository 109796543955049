import React, { Fragment } from "react"
import Navbar3 from "../../components/Navbar3/Navbar3"
import PageTitle from "../../components/pagetitle/PageTitle"
import Scrollbar from "../../components/scrollbar/scrollbar"
import Footer2 from "../../components/footer2/Footer2"
import shape1 from "../../images/team/shape6.png"
import shape2 from "../../images/progress-shape-1.png"
import shape3 from "../../images/progress-shape-2.png"
import brassimage from "../../images/slider/4.png"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

const BRASSPage = () => {
  return (
    <Fragment>
      <Navbar3 />
      <PageTitle pageTitle={"BRASS"} pagesub={"Products"} />
      <div className="team-pg-area">
        <div className="team-inner section-padding">
          <div className="container">
            <div className="team-info-wrap">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="team-info-img">
                    <img src={brassimage} alt="" />
                    <div className="shape">
                      <img src={shape1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="education-area ex-wiget">
                    <h2>BRASS</h2>
                    <p>
                      BRASS is the state-of-the art product reliability
                      assessment tool that helps engineers to develop
                      reliability estimates for products in design or already in
                      operation. T his tool enables you to develop these
                      estimates where this previously seemed impossible because
                      reliability performance data is limited or completely
                      absent.
                    </p>
                    <p>
                      BRASS gives you the tools needed to leverage valuable
                      sources of information, such as data from legacy designs,
                      industry handbooks, vendor-supplied estimates, and
                      engineering knowledge into your assessments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-shape-1">
            <img src={shape2} alt="" />
          </div>
          <div className="pr-shape-2">
            <img src={shape3} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="keyfeature-area ex-wiget">
            <h2>Key Features</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="wpo-project-single-title">
                  <h3>Powerful Reliability Modeling</h3>
                </div>
                <ul>
                  <li>
                    Transform system and component-level data, as well as
                    engineering judgments into system and component-level
                    reliability assessments.
                  </li>
                  <li>
                    Analyze accelerated life test data, with certain and
                    uncertain acceleration factors.
                  </li>
                  <li>Analyze warranty data.</li>
                  <li>
                    Assess and track reliability growth with powerful features
                    including the ability to account for reliability design
                    modifications, test results, and data discounting rule
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 list-widget-s">
                <div className="wpo-project-single-title">
                  <h3>Full Representation of Uncertainties</h3>
                </div>
                <ul>
                  <li>Specify uncertainties regarding the inputs</li>
                  <li>Compute the uncertainty surrounding all estimates</li>
                  <li>
                    Select from a variety of options for the specification of
                    prior information
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="wpo-project-single-title">
                  <h3>Variety of Presentation Features</h3>
                </div>
                <ul>
                  <li>
                    Estimate reliability, failure intensity, and cumulative
                    failure intensity as a function of time View estimation
                    results in tabular and graphical format
                  </li>
                  <li>
                    Copy chart and tabular data to the system clipboard, for use
                    in other software applications
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 list-widget-s">
                <div className="wpo-project-single-title">
                  <h3>Bayesian Analysis Procedures</h3>
                </div>
                <ul>
                  <li>Fully Bayesian analysis</li>
                  <li>Easily combine different kinds of information</li>
                  <li>
                    Access state-of-the-art Bayesian analysis without need for
                    scripting
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="download-area">
            <h2>Downloads</h2>
            <Table className="download-table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "18px" }}>Products</TableCell>
                  <TableCell style={{ fontSize: "18px" }}>Version</TableCell>
                  <TableCell style={{ fontSize: "18px" }}>
                    Release Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="download-table-body">
                <TableRow>
                  <TableCell style={{ fontSize: "16px" }}>BRASS</TableCell>
                  <TableCell style={{ fontSize: "16px" }}>
                    <a href="downloads/brass-2.0.0.exe">2.0.0</a>
                  </TableCell>
                  <TableCell style={{ fontSize: "16px" }}>1/1/2024</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: "16px" }}>BRASS</TableCell>
                  <TableCell style={{ fontSize: "16px" }}>
                    <a href="downloads/brass-eval-1.3.0.msi">1.3.0</a>
                  </TableCell>
                  <TableCell style={{ fontSize: "16px" }}>6/1/2013</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <Footer2 />
      <Scrollbar />
    </Fragment>
  )
}
export default BRASSPage
